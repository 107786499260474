import { polyfill } from 'es6-promise'; polyfill();

import Logo from '../img/logo-keukenboer.svg';
import LogoWhite from '../img/logo-keukenboer-wit.svg';
import LogoGreen from '../img/logo-keukenboer-green.svg';
import LogoColorBlock from '../img/logo-van-geloof-kleur-blok.jpg';
import FotoKeuken from '../img/foto-keuken.jpg';
import FotoKeukenBg from '../img/foto-keuken-bg.jpg';
import VanGeloofFoto from '../img/van-geloof-foto.jpg';
import VanGeloofFotoBg from '../img/van-geloof-foto-bg.jpg';
import VanGeloofFotoBgV2 from '../img/van-geloof-foto-bg-2.jpg';
import SidebarCollectionBlockBg from '../img/sidebar-keukencollectie-bg.png';
import SidebarShowroomBlockBg from '../img/sidebar-showroom-bg.png';
import SidebarFolderBlockBg from '../img/sidebar-folder-bg.png';
import CtaBg from '../img/cta-bg.jpg';
import QuotationBg from '../img/offerte-checker.png';
import HeaderImage1 from '../img/home-image-1.jpg';
import HeaderImage1Png from '../img/home-image-1.png';
import HomeBg1 from '../img/home-review-bg.jpg';
import HomeBg2 from '../img/home-about-us-bg.jpg';
import ReviewImg from '../img/review-img.jpg';
import KdcLogo from '../img/keukenbladenconcurrent-logo.svg';
import KbcLogo from '../img/keukendeurenconcurrent-logo.svg';
import HeaderIllustration from '../img/header-illustration.png';
import WhiteBgPattern from '../img/white-bg-pattern.jpg';
import DiyKitchenHexagon from '../img/diy-kitchen-hexagon.png';
import SaleKitchenHexagon from '../img/sale-kitchen-hexagon.png';
import FaqHexagon from '../img/faq-hexagon.png';
import InfoBlockBg from '../img/info-block-bg.jpg';

// App main
const main = async () => {
    import(/* webpackChunkName: "Styles" */ '../scss/base.scss');
    import(/* webpackChunkName: "Fancybox" */ './fancybox-settings');
    import(/* webpackChunkName: "Font Awesome" */ './font-awesome-settings');
    import(/* webpackChunkName: "Search" */ './search-settings');
    import(/* webpackChunkName: "LazyLoadSettings" */ './lazyload-settings.js');
    import(/* webpackChunkName: "jQuery" */ 'jquery');

    // Pages
    import(/* webpackChunkName: "Blog" */ './pages/blog-settings');
    import(/* webpackChunkName: "Appointment" */ './pages/appointment-settings');
    import(/* webpackChunkName: "DownloadForm" */ './pages/downloadform-settings');
    import(/* webpackChunkName: "KitchenFilterSettings" */ './pages/kitchen-filter-settings');
    import(/* webpackChunkName: "IntakeKitchenSettings" */ './pages/intake-kitchen-settings');
};

// Execute async function
main().then((value) => {
});

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/sw.js').then(function(registration) {
            // Registration was successful
            console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function(err) {
            // registration failed :(
            console.log('ServiceWorker registration failed: ', err);
        });
    });
}